import { create } from "zustand";
import { httpApi } from "@/http-api";
import type { StripeInvoice } from "@/http-api";

interface BillingState {
  invoices: StripeInvoice[];
  isLoading: boolean;
  error: string | null;
  currentPlan: {
    name: string;
    renewalDate: Date | null;
  } | null;
}

interface BillingActions {
  loadInvoices: () => Promise<void>;
  downloadInvoice: (invoiceId: string) => Promise<void>;
  cancelSubscription: () => Promise<void>;
  updatePaymentMethod: (paymentMethodId: string) => Promise<void>;
}

export const useBillingStore = create<BillingState & BillingActions>((set, get) => ({
  invoices: [],
  isLoading: false,
  error: null,
  currentPlan: null,

  loadInvoices: async () => {
    try {
      set({ isLoading: true, error: null });
      const { data: invoices } = await httpApi.getInvoices();
      console.log("Invoices:", invoices);

      // Sort invoices by creation date, newest first
      const sortedInvoices = [...invoices].sort((a, b) => b.createdAt - a.createdAt);

      set({
        invoices: sortedInvoices,
        isLoading: false,
      });
    } catch (error) {
      console.error("Failed to load invoices:", error);
      set({
        error: "Failed to load billing history",
        isLoading: false,
      });
    }
  },

  downloadInvoice: async (invoiceId: string) => {
    try {
      const invoice = get().invoices.find((inv) => inv.id === invoiceId);
      if (invoice?.invoicePdf) {
        window.open(invoice.invoicePdf, "_blank");
      }
    } catch (error) {
      console.error("Failed to download invoice:", error);
      set({ error: "Failed to download invoice" });
    }
  },

  cancelSubscription: async () => {
    try {
      set({ isLoading: true, error: null });
      // TODO: Implement when API endpoint is available
      // await httpApi.cancelSubscription();
      set({ isLoading: false });
    } catch (error) {
      console.error("Failed to cancel subscription:", error);
      set({
        error: "Failed to cancel subscription",
        isLoading: false,
      });
    }
  },

  updatePaymentMethod: async (paymentMethodId: string) => {
    try {
      set({ isLoading: true, error: null });
      // TODO: Implement when API endpoint is available
      // await httpApi.updatePaymentMethod(paymentMethodId);
      set({ isLoading: false });
    } catch (error) {
      console.error("Failed to update payment method:", error);
      set({
        error: "Failed to update payment method",
        isLoading: false,
      });
    }
  },
}));

// Helper hooks for common billing operations
export const useInvoices = () => useBillingStore((state) => state.invoices);
export const useBillingLoading = () => useBillingStore((state) => state.isLoading);
export const useBillingError = () => useBillingStore((state) => state.error);
export const useCurrentPlan = () => useBillingStore((state) => state.currentPlan);
