import React from "react";
import { Loader2 } from "lucide-react";
import { cn } from "@/components/utils";
import { Progress } from "@/components/ui/progress";

interface LoadingOverlayProps extends React.HTMLAttributes<HTMLDivElement> {
  message?: string;
  showProgress?: boolean;
  progress?: number;
}

export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  message = "Loading device data...",
  showProgress = false,
  progress = 0,
  className,
  ...props
}) => {
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  if (!mounted) return null;

  return (
    <div
      className={cn(
        "fixed inset-0 top-16 bg-background/80 backdrop-blur-sm flex items-center justify-center z-50",
        "transition-opacity duration-300",
        mounted ? "opacity-100" : "opacity-0",
        className
      )}
      {...props}
    >
      <div className="flex flex-col items-center space-y-4 p-6 bg-background rounded-lg shadow-lg">
        <Loader2 className="h-10 w-10 animate-spin text-primary" />
        <p className="text-sm font-medium text-muted-foreground">{message}</p>
        {showProgress && (
          <div className="w-full max-w-xs">
            <Progress value={progress} className="h-2" />
            <p className="text-xs text-muted-foreground text-center mt-2">{progress}% complete</p>
          </div>
        )}
      </div>
    </div>
  );
};
