import ColorLogo from "@/assets/svg/Color-logo-no-background.svg";
import TableShowImage from "@/assets/tablet-show-dashboard.png";
import ExteriorInstallation from "@/assets/exterior-installation.png";
import MonitorHistoricalData from "@/assets/monitor-historical-data.png";
import PhoneSensorData2 from "@/assets/all-media-data.png";
import { Button } from "@/components/ui/button";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Mail, MapPin, PhoneCall } from "lucide-react";

export const LandingPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div>
      <section className="xl:h-[768px] flex bg-home-pattern overflow-hidden">
        <div className="container max-w-screen-2xl md:px-16 mx-auto mt-6 flex flex-col">
          <div className="flex justify-start">
            <img src={ColorLogo} alt="Logo" className="h-[76px] object-fill mb-2" />
          </div>
          <div className="flex flex-col h-full xl:flex-row w-full">
            <div className="w-full xl:max-w-2xl my-8 text-center xl:text-left lg:items-start">
              <motion.h1
                className="mb-8 font-normal text-center w-full xl:text-left xl:max-w-2xl text-White text-2xl sm:text-4xl xl:text-7xl "
                whileInView={{ x: 0 }}
                initial={{ x: -100 }}
                transition={{ duration: 0.4 }}
              >
                Monitor your HVAC critical data in real time!
              </motion.h1>
              <motion.p
                className="mt-8 font-inter font-normal xl:max-w-2xl text-White text-base sm:text-xl xl:text-2xl"
                whileInView={{ x: 0 }}
                initial={{ x: -100 }}
                transition={{ duration: 0.7 }}
              >
                View relevant statistics in real time. Get notifications, alarms, average consumption and direct contact
                with maintenance
              </motion.p>
            </div>
            <div className="flex flex-col space-y-4 sm:space-y-12">
              <div className="flex flex-col h-1/2 w-full justify-center space-y-2 sm:mt-8 sm:pl-8">
                <motion.div
                  className="flex justify-center w-full"
                  whileInView={{ x: 0 }}
                  initial={{ x: 100 }}
                  transition={{ duration: 0.5 }}
                >
                  <Button
                    className="bg-yellow-vivid w-1/3 justify-self-center rounded-2xl"
                    onClick={() => {
                      navigate("/auth/signin");
                    }}
                  >
                    Sign in
                  </Button>
                </motion.div>
                <span className="text-White text-center">or </span>
                <motion.div
                  className="flex justify-center w-full"
                  whileInView={{ x: 0 }}
                  initial={{ x: 100 }}
                  transition={{ duration: 0.6 }}
                >
                  <Button
                    className="bg-cyan-vivid w-full w-1/3 justify-self-center rounded-2xl"
                    onClick={() => {
                      navigate("/auth/signup");
                    }}
                  >
                    Sign up
                  </Button>
                </motion.div>
              </div>
              <motion.div
                className="flex justify-center items-center pt-4"
                whileInView={{ x: 0 }}
                initial={{ x: 100 }}
                transition={{ duration: 0.6 }}
              >
                <div className="flex justify-center items-end">
                  <img src={TableShowImage} alt="Dashboard-with-sensor-data" />
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </section>
      <section className="flex items-center justify-center text-foreground px-4">
        <div className="container flex flex-col max-w-screen-xl mx-auto mt-6">
          <div className="flex flex-col h-full sm:flex-row w-full">
            <div className="flex flex-col w-full sm:max-w-sm md:max-w-lg my-2 sm:my-8 text-center xl:text-left lg:items-start">
              <motion.h1
                className="mb-10 xl:mb-16 font-medium text-2xl sm:text-4xl xl:text-6xl"
                whileInView={{ y: 0 }}
                initial={{ y: 80 }}
                transition={{ duration: 0.4 }}
              >
                Monitor your HVAC from everywhere
              </motion.h1>
              <p className="mb-8 font-inter font-light text-base text-left  md:text-lg  xl:text-xl">
                The values emitted by your HVAC are compared in real time to its healthy operating range. We offer a
                real-time monitoring device for the values of pressure and temperature in low and high lines, plus
                currents flowing through the compressor and fan in the condenser unit, notifying relevant parties
                whenever an anomaly appears.
              </p>
            </div>
            <motion.div
              className="flex flex-col justify-center items-center xl:pl-16 overflow-hidden"
              whileInView={{ y: 0 }}
              initial={{ y: 90 }}
              transition={{ duration: 0.6 }}
            >
              <img src={ExteriorInstallation} alt="w-9/12 h-9/12 Dashboard-with-sensor-data" />
            </motion.div>
          </div>
        </div>
      </section>
      <section className="flex text-foreground">
        <div className="container flex max-w-screen-xl mx-auto pt-6">
          <div className="flex flex-col sm:flex-row h-full w-full space-x-8">
            <div className="flex items-center shrink flex order-2 sm:order-1">
              <img src={MonitorHistoricalData} alt="Dashboard-with-sensor-data" />
            </div>
            <div className="flex flex-col flex-none max-w-sm lg:max-w-lg sm:my-8 items-center text-center xl:text-left lg:items-start xl:pl-16 order-1 sm:order-2">
              <motion.h1
                className="mb-8 font-medium text-2xl sm:text-4xl xl:text-6xl"
                whileInView={{ x: 0 }}
                initial={{ x: -50 }}
                transition={{ duration: 0.6 }}
              >
                Get reports about historial data
              </motion.h1>
              <motion.p
                className="mb-8 font-inter font-light text-base text-left  md:text-lg  xl:text-xl"
                whileInView={{ x: 0 }}
                initial={{ x: -50 }}
                transition={{ duration: 0.9 }}
              >
                We offer you the details of the recorded results as well as a summary page of all the monitored
                equipment. You can obtain an approximate calculation of energy consumption in the selected period and
                estimate costs according to average consumption behavior.
              </motion.p>
            </div>
          </div>
        </div>
      </section>
      <section className="flex bg-slate-100 items-center justify-center text-foreground ">
        <div className="container max-w-screen-xl mx-auto my-6 flex flex-col">
          <div className="flex flex-col h-full sm:flex-row w-full">
            <div className="flex flex-col max-w-lg my-2 sm:my-8 text-center xl:text-left lg:items-start">
              <motion.h1
                className="mb-10 xl:mb-16 font-medium text-2xl sm:text-4xl xl:text-6xl"
                whileInView={{ y: 0 }}
                initial={{ y: 50 }}
                transition={{ duration: 0.3 }}
              >
                Get alarms on failures or performance tip
              </motion.h1>
              <p className="mb-8 font-inter font-light text-base text-left  md:text-lg  xl:text-xl">
                In case of failures or unexpected values in the records, maintenance suggestions and alarms are
                displayed, easily configurable in your preferred browser or on your mobile via SMS.
              </p>
            </div>
            <motion.div
              className="flex flex-col justify-center items-center p-6 sm:p-0 xl:pl-16 overflow-hidden"
              whileInView={{ y: 0 }}
              xl:flex-row
              initial={{ y: 60 }}
              transition={{ duration: 0.4 }}
            >
              <img src={PhoneSensorData2} alt="Dashboard-with-sensor-data" />
            </motion.div>
          </div>
        </div>
      </section>
      <section className="h-full bg-strong-cyan items-center justify-center">
        <div className="container flex flex-col max-w-screen-2xl sm:flex-row px-8 xl:px-0 sm:mx-auto pt-6 space-x-0 sm:space-x-8">
          <div className="flex justify-center max-w-md items-center">
            <img src={ColorLogo} alt="Logo" className="h-[76px] object-fill mb-2" />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3  divide-none sm:divide-x text-White">
            <div></div>
            <div></div>
            <div id="Get In Touch" className="flex flex-col space-y-4 justify-end">
              <h1 className="mb-4 my-4 font-normal font-semibold text-lg uppercase sm:text-xl xl:text-2xl">
                Contact US
              </h1>
              <div className="flex flex-row space-x-2">
                <div className="bg-yellow-vivid p-2 rounded-full">
                  <PhoneCall className="text-cyan-dark" />
                </div>
                <span className="p-2">+1 (480) 253-9376</span>
              </div>
              <div className="flex flex-row space-x-2">
                <div className="bg-yellow-vivid p-2 rounded-full">
                  <Mail className="text-cyan-dark" />
                </div>
                <span className="p-2">services@katenaria.com</span>
              </div>
              <div className="flex flex-row space-x-4 items-center">
                <div className="bg-yellow-vivid p-2 rounded-full">
                  <MapPin className="text-cyan-dark" />
                </div>
                <span>131 Continental Dr Suite 305 Newark, DE, 19713 US</span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row max-w-screen-xl mx-4 sm:mx-auto justify-center border-t mt-4 sm:mt-10">
          <div className="flex max-w-lg justify-center">
            <div className="mx-4 my-6 sm:m-10">
              <span className="text-sm sm:text-base text-White">©Cool Guardian 2024. All rights reserved.</span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
