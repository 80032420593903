import React, { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@/components/ui/theme-provider";
import { Thermometer, Gauge, Zap, AlertTriangle, BarChart, Shield } from "lucide-react";
import WhiteLogo from "@/assets/Cyan-black-logo.png";
import DarkLogo from "@/assets/Logo-DarkMode.png";
import TabletShow from "@/assets/tablet-show-dashboard.png";
import cardHVAC1 from "@/assets/signin/cool-guardian-hvac-1.jpeg";
import cardHVAC2 from "@/assets/signin/cool-guardian-hvac-2.jpeg";
import cardHVAC3 from "@/assets/signin/cool-guardian-hvac-3.jpeg";

interface AuthLayoutProps {
  children: ReactNode;
}

export const AuthLayout = ({ children }: AuthLayoutProps) => {
  const navigate = useNavigate();
  const { theme } = useTheme();
  const [currentCard, setCurrentCard] = useState(0);

  const cards = [
    {
      title: "Temperature Monitoring",
      icon: Thermometer,
      content: "Optimize comfort and energy efficiency with precise temperature control.",
      image: cardHVAC1,
    },
    {
      title: "Pressure Monitoring",
      icon: Gauge,
      content: "Ensure system integrity and prevent breakdowns with real-time pressure tracking.",
      image: cardHVAC2,
    },
    {
      title: "Current Monitoring",
      icon: Zap,
      content: "Reduce energy consumption and detect electrical issues early.",
      image: cardHVAC3,
    },
    {
      title: "Constant tracks your HVAC",
      icon: Shield,
      content: "Identifies issues, and detects deeper problems like aging capacitors and performance degradation.",
      image: cardHVAC1,
    },
    {
      title: "Performance Visualization",
      icon: BarChart,
      content: "Detailed dashboards, historical data, and monthly reports, including power consumption insights.",
      image: TabletShow,
    },
    {
      title: "Early Detection",
      icon: AlertTriangle,
      content: "Spot minor issues before they escalate, ensuring efficient operation and protecting your investment.",
      image: cardHVAC2,
    },
  ];

  const showLogo = () => {
    return theme === "dark" ? (
      <img src={DarkLogo} alt="DarkLogo" className="h-10 object-contain" />
    ) : (
      <img src={WhiteLogo} alt="WhiteLogo" className="h-10 object-contain" />
    );
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentCard((prevCard) => (prevCard + 1) % cards.length);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-br from-cyan-50 to-cyan-100">
      <div className="flex-grow flex items-center justify-center p-4">
        <div className="w-full max-w-7xl grid md:grid-cols-2 bg-white rounded-2xl shadow-xl overflow-hidden">
          {/* Left side - Login Form */}
          <div className="p-8 lg:p-12 flex flex-col justify-between">
            <div className="space-y-6">
              <button className="flex items-center mb-2 hover:cursor-pointer" onClick={() => navigate("/")}>
                {showLogo()}
              </button>
              {children}
            </div>
            <div className="mt-8 text-sm text-foreground font-thin">
              <p>Cool Guardian: Protecting your HVAC investment</p>
            </div>
          </div>

          {/* Right side - Rotating Cards */}
          <div className="hidden md:flex relative bg-gradient-to-br from-cyan-vivid to-cyan-dark p-8">
            {/* Decorative geometric patterns */}
            <div className="absolute inset-0 opacity-10">
              <div className="absolute top-0 left-0 w-40 h-40 border-4 border-white rounded-full transform -translate-x-1/2 -translate-y-1/2"></div>
              <div className="absolute bottom-0 right-0 w-60 h-60 border-4 border-white rounded-full transform translate-x-1/2 translate-y-1/2"></div>
              <div className="absolute top-1/2 left-1/2 w-20 h-20 bg-white rounded-lg transform -translate-x-1/2 -translate-y-1/2 rotate-45"></div>
            </div>
            {cards.map((card, index) => (
              <div
                key={index}
                className={`absolute inset-0 transition-opacity duration-1000 ${
                  index === currentCard ? "opacity-100" : "opacity-0"
                }`}
              >
                <div className="absolute inset-0 h-5/6 flex flex-col pt-20 text-white">
                  <img src={card.image} alt={card.title} />
                </div>
                <div className="absolute flex flex-col inset-0 justify-end text-White p-8">
                  <div className="flex flex-col items-center justify-center">
                    <card.icon className="w-12 h-12 mb-4 text-cyan-100" />
                    <h3 className="text-2xl font-extralight tracking-wide mb-4">{card.title}</h3>
                  </div>
                  <p className="text-sm text-center tracking-normal font-light mb-20">{card.content}</p>
                  <div className="flex space-x-2 justify-center mt-20">
                    {cards.map((_, i) => (
                      <button
                        key={i}
                        className={`w-3 h-3 rounded-full ${i === currentCard ? "bg-white" : "bg-gray-400"}`}
                        onClick={() => setCurrentCard(i)}
                      />
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
