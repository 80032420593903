import React, { useEffect, useCallback } from "react";
import { useAuthStore } from "@/features/auth/auth-store";
import { useDashboardStore } from "@/features/dashboard/dashboard-store";
import { useSensorStore } from "@/features/dashboard/sensor-store";
import { useSearchParams } from "react-router-dom";
import { LoadingOverlay } from "./LoadingOverlay";
import { DashboardSidebar } from "./sidebar/DashboardSidebar";
import { DeviceContent } from "./device/DeviceContent";
import { useMediaQuery } from "@/hooks/use-media-query";
import { cn } from "@/components/utils";
import { StatusSection } from "./status/StatusSection";

export const DashboardPage = () => {
  const [searchParams] = useSearchParams();
  const deviceId = searchParams.get("deviceId");
  const isMobile = useMediaQuery("(max-width: 768px)");

  const isNewUser = useAuthStore(useCallback((state) => state.isNewUser, []));

  const { status, isExpanded } = useDashboardStore(
    useCallback(
      (state) => ({
        status: state.ui.status,
        isExpanded: state.isExpanded,
      }),
      []
    )
  );

  const {
    customerDevices,
    devices,
    selectedDevice,
    ui: { isPartner },
  } = useDashboardStore(
    useCallback(
      (state) => ({
        customerDevices: state.customerDevices,
        devices: state.devices,
        selectDevice: state.selectDevice,
        selectedDevice: state.selectedDevice,
        ui: {
          isPartner: state.ui.isPartner,
        },
      }),
      []
    )
  );

  const { loadDevices, selectDevice, setDrawerOpen } = useDashboardStore(
    useCallback(
      (state) => ({
        loadDevices: state.loadDevices,
        selectDevice: state.selectDevice,
        setDrawerOpen: state.setDrawerOpen,
      }),
      []
    )
  );

  useEffect(() => {
    loadDevices().then(() => {
      if (deviceId) {
        console.log("Selecting device", deviceId);
        selectDevice(deviceId);
        return;
      }
      if (devices.length === 1) {
        console.log("Auto-selecting device", devices[0].deviceId);
        selectDevice(devices[0].deviceId);
        return;
      }
      setDrawerOpen(true);
    });
  }, [deviceId]);

  const isLoading = useSensorStore((state) => state.isLoading);

  return (
    <div className="flex min-h-screen">
      <DashboardSidebar />
      <main
        className={cn(
          "flex-1 transition-all duration-300 overflow-x-hidden",
          isMobile ? "ml-0 px-2" : isExpanded ? "ml-[350px]" : "ml-16",
          "py-4"
        )}
      >
        {selectedDevice?.subscribed && isLoading && <LoadingOverlay className="fixed inset-0 z-50" />}

        <div className="relative w-full max-w-7xl mx-auto py-4 px-2 sm:px-6 lg:px-8">
          <div className="space-y-4 sm:space-y-6">
            <StatusSection isNewUser={isNewUser} status={status} />
            <DeviceContent selectedDevice={selectedDevice} />
          </div>
        </div>
      </main>
    </div>
  );
};
