import { Button } from "@/components/ui/button";
import { DeviceHistoricalData } from "./DeviceHistoricalData";
import { DeviceInfoCard } from "./DeviceInfoCard";
import { SystemStatusCard } from "../SystemStatusCard";
import { useNavigate } from "react-router-dom";
import { DeviceStatistics } from "./DeviceStatistics";

export const DeviceContent = ({ selectedDevice }) => {
  const navigate = useNavigate();
  if (!selectedDevice) {
    return (
      <div className="flex flex-col items-center justify-center h-[400px] bg-muted/10 rounded-lg">
        <h3 className="text-2xl text-foreground dark:text-blue-brand">Select a device to view analytics</h3>
      </div>
    );
  }

  return (
    <div className="w-full max-w-full overflow-x-hidden">
      <div className="flex justify-between items-center pb-3 flex-wrap">
        <h3 className="text-2xl sm:text-3xl font-bold tracking-tight break-words">
          {selectedDevice.monitoring?.brand || "Device"} {selectedDevice.subscribe ? "Analytics" : ""}
        </h3>
      </div>

      {selectedDevice.subscribed ? (
        <div className="space-y-6">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 py-6">
            <SystemStatusCard device={selectedDevice} />
            <DeviceInfoCard device={selectedDevice} />
          </div>
          <DeviceStatistics />
          <DeviceHistoricalData />
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-[400px] bg-muted/10 rounded-lg">
          <div className="w-full max-w-lg pb-6">
            <DeviceInfoCard device={selectedDevice} />
          </div>
          <h3 className="text-xl sm:text-2xl text-foreground dark:text-blue-brand text-center px-2">
            No active subscription
          </h3>
          <p className="text-sm sm:text-base text-muted-foreground dark:text-blue-brand py-6 text-center px-2">
            Please subscribe to a plan to view device analytics
          </p>
          <Button onClick={() => navigate(`/payment/subscription-options?deviceId=${selectedDevice.deviceId}`)}>
            Subscribe Now
          </Button>
        </div>
      )}
    </div>
  );
};
