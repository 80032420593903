// DeviceStatistics.tsx
import { useEffect } from "react";
import { Activity, Power, Timer, Gauge, Fan } from "lucide-react";
import { useStatsStore } from "../stats-store";
import { useDashboardStore } from "../dashboard-store";
import { StatisticCard } from "../StatisticCard";

const calculateComparison = (current: number, previous: number): number => {
  if (previous === 0) return 0;
  return ((current - previous) / previous) * 100;
};

export const DeviceStatistics = () => {
  const selectedDevice = useDashboardStore((state) => state.selectedDevice);
  const { stats, initializeStream, cleanupStream } = useStatsStore();
  const currentStats = stats["24h"];
  const weekStats = stats["7d"];
  const monthStats = stats["30d"];
  console.log("stats", stats);

  useEffect(() => {
    if (selectedDevice?.kernelIds?.[0]) {
      initializeStream(selectedDevice.kernelIds[0]);
      return () => cleanupStream();
    }
  }, [selectedDevice?.kernelIds]);

  if (!currentStats || !weekStats || !monthStats) return null;

  const statsConfig = [
    {
      title: "Cycles Count",
      value: currentStats.cyclesCount,
      unit: "cycles",
      icon: Activity,
      comparison: {
        weekly: calculateComparison(currentStats.cyclesCount, weekStats.cyclesCount),
        monthly: calculateComparison(currentStats.cyclesCount, monthStats.cyclesCount),
      },
    },
    {
      title: "Power Consumption",
      value: currentStats.consumption,
      unit: "kWh",
      icon: Power,
      comparison: {
        weekly: calculateComparison(currentStats.consumption, weekStats.consumption),
        monthly: calculateComparison(currentStats.consumption, monthStats.consumption),
      },
    },
    {
      title: "Run Time Range",
      value: currentStats.longestRunTime,
      unit: "min",
      icon: Timer,
      comparison: {
        weekly: calculateComparison(currentStats.longestRunTime, weekStats.longestRunTime),
        monthly: calculateComparison(currentStats.longestRunTime, monthStats.longestRunTime),
      },
    },
    {
      title: "Compressor Current",
      value: currentStats.avgRunningCurrentCompressor,
      unit: "A",
      peak: currentStats.maxPeakCurrentCompressor,
      icon: Gauge,
      showPeak: true,
      comparison: {
        weekly: calculateComparison(currentStats.avgRunningCurrentCompressor, weekStats.avgRunningCurrentCompressor),
        monthly: calculateComparison(currentStats.avgRunningCurrentCompressor, monthStats.avgRunningCurrentCompressor),
      },
    },
    {
      title: "Fan Current",
      value: currentStats.avgRunningCurrentFan,
      unit: "A",
      peak: currentStats.maxPeakCurrentFan,
      icon: Fan,
      showPeak: true,
      comparison: {
        weekly: calculateComparison(currentStats.avgRunningCurrentFan, weekStats.avgRunningCurrentFan),
        monthly: calculateComparison(currentStats.avgRunningCurrentFan, monthStats.avgRunningCurrentFan),
      },
    },
  ];

  // Only add blower stats if they exist
  if (
    currentStats.avgRunningCurrentBlower !== undefined &&
    weekStats.avgRunningCurrentBlower !== undefined &&
    monthStats.avgRunningCurrentBlower !== undefined
  ) {
    statsConfig.push({
      title: "Blower Current",
      value: currentStats.avgRunningCurrentBlower,
      unit: "A",
      peak: currentStats.maxPeakCurrentBlower,
      icon: Fan,
      showPeak: true,
      comparison: {
        weekly: calculateComparison(currentStats.avgRunningCurrentBlower, weekStats.avgRunningCurrentBlower),
        monthly: calculateComparison(currentStats.avgRunningCurrentBlower, monthStats.avgRunningCurrentBlower),
      },
    });
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-medium">Last 24 Hours Statistics (Mocked)</h3>
      </div>

      <div className="grid gap-4 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-5">
        {statsConfig.map((stat, index) => (
          <StatisticCard key={index} {...stat} />
        ))}
      </div>
    </div>
  );
};
