import React, { useEffect, useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ChevronLeft, ChevronRight, CreditCard, Download } from "lucide-react";
import { useBillingStore } from "./billing-store";
import { format } from "date-fns";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

export const BillingPanel = () => {
  const { loadInvoices, downloadInvoice, cancelSubscription } = useBillingStore();
  const invoices = useBillingStore((state) => state.invoices);
  const isLoading = useBillingStore((state) => state.isLoading);
  const error = useBillingStore((state) => state.error);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const totalPages = Math.ceil(invoices.length / itemsPerPage);

  const paginatedOrders = invoices.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handleItemsPerPageChange = (value: string) => {
    setItemsPerPage(Number(value));
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  useEffect(() => {
    loadInvoices();
  }, [loadInvoices]);
  if (isLoading) {
    return <div>Loading billing information...</div>;
  }
  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="space-y-8">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <Card className="lg:col-span-2">
          <CardHeader>
            <CardTitle>Order History</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-8 space-y-4 sm:space-y-0">
              <p className="text-base text-muted-foreground">Manage billing information and view receipts</p>
              <div className="flex items-center space-x-2">
                <p className="text-sm text-muted-foreground">Show</p>
                <Select value={itemsPerPage.toString()} onValueChange={handleItemsPerPageChange}>
                  <SelectTrigger className="w-[60px] sm:w-[70px]">
                    <SelectValue placeholder={itemsPerPage} />
                  </SelectTrigger>
                  <SelectContent>
                    {[5, 10, 20, 50].map((number) => (
                      <SelectItem key={number} value={number.toString()}>
                        {number}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <p className="text-xs sm:text-sm text-muted-foreground">per page</p>
              </div>
            </div>
            <div className="overflow-x-auto">
              <table className="w-full min-w-[600px]">
                <thead>
                  <tr className="text-xs sm:text-sm font-medium text-muted-foreground border-b text-center">
                    <th className="w-1/4 pb-2 text-left">Date</th>
                    <th className="w-1/4 pb-2 text-left">Email</th>
                    <th className="w-1/4 pb-2">Amount</th>
                    <th className="w-1/4 pb-2">Receipt</th>
                  </tr>
                </thead>
                <tbody>
                  {invoices.map((invoice) => (
                    <tr
                      key={invoice.id}
                      className="border-b last:border-b-0 hover:bg-muted/50 transition-colors duration-200"
                    >
                      <td className="py-4 text-xs sm:text-sm text left">
                        {format(new Date(invoice.createdAt * 1000), "MMM. dd, yyyy")}
                      </td>
                      <td className="py-4 text-xs sm:text-sm font-medium">{invoice.customerEmail}</td>
                      <td className="py-4 text-xs sm:text-sm text-center">
                        {" "}
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: invoice.currency,
                        }).format(invoice.amountDue / 100)}
                      </td>
                      <td className="py-4 text-center">
                        <Button
                          variant="ghost"
                          size="sm"
                          className="hover:bg-primary hover:text-primary-foreground transition-colors duration-200"
                          onClick={() => invoice.id && downloadInvoice(invoice.id)}
                        >
                          <Download className="h-3 w-3 sm:h-4 sm:w-4 mr-1 sm:mr-2" />
                          <span className="text-xs sm:text-sm">Download</span>
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="mt-6 flex justify-center items-center space-x-1">
              <Button
                variant="outline"
                size="sm"
                onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}
                disabled={currentPage === 1}
                className="transition-colors duration-200"
              >
                <ChevronLeft className="h-4 w-4" />
              </Button>
              {[...Array(totalPages)].map((_, index) => {
                const pageNumber = index + 1;
                // Show first page, last page, current page, and pages around current page
                if (
                  pageNumber === 1 ||
                  pageNumber === totalPages ||
                  (pageNumber >= currentPage - 1 && pageNumber <= currentPage + 1)
                ) {
                  return (
                    <Button
                      key={pageNumber}
                      variant={currentPage === pageNumber ? "default" : "outline"}
                      size="sm"
                      onClick={() => setCurrentPage(pageNumber)}
                      className="hidden sm:inline-flex transition-colors duration-200 min-w-[2.5rem]"
                    >
                      {pageNumber}
                    </Button>
                  );
                } else if (pageNumber === currentPage - 2 || pageNumber === currentPage + 2) {
                  return (
                    <Button
                      key={pageNumber}
                      variant="outline"
                      size="sm"
                      disabled
                      className="hidden sm:inline-flex transition-colors duration-200"
                    >
                      ...
                    </Button>
                  );
                }
                return null;
              })}
              <Button
                variant="outline"
                size="sm"
                onClick={() => setCurrentPage(Math.min(totalPages, currentPage + 1))}
                disabled={currentPage === totalPages}
                className="transition-colors duration-200"
              >
                <ChevronRight className="h-4 w-4" />
              </Button>
            </div>
          </CardContent>
        </Card>
        <div className="space-y-8 lg:space-y-6">
          <Card className="bg-cyan-vivid text-cyan-900 text-primary-foreground">
            <CardHeader>
              <CardTitle className="text-lg sm:text-xl font-semibold">Your Plan</CardTitle>
            </CardHeader>
            <CardContent>
              <h3 className="text-2xl sm:text-3xl font-bold mb-2">Pro Annual</h3>
              <p className="text-xs sm:text-sm opacity-90 mb-6">Renews on Nov. 2021</p>
              <Button
                variant="secondary"
                className="w-full hover:bg-primary-foreground hover:text-primary transition-colors duration-200"
                onClick={cancelSubscription}
              >
                <span className="text-xs sm:text-sm">Cancel subscription</span>
              </Button>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle className="text-lg sm:text-xl font-semibold">Payment Method</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-xs sm:text-sm text-muted-foreground mb-4">Current payment method on file</p>
              <div className="flex items-center justify-between p-3 sm:p-4 border rounded-lg bg-muted/30">
                <div className="flex items-center gap-2 sm:gap-4">
                  <div className="w-10 h-6 sm:w-12 sm:h-8 bg-gradient-to-r from-yellow-vivid to-cyan-vivid rounded flex items-center justify-center text-white text-[10px] sm:text-xs font-bold">
                    VISA
                  </div>
                  <span className="text-xs sm:text-sm font-medium">•••• •••• •••• 2255</span>
                </div>
                <Button
                  variant="ghost"
                  size="sm"
                  className="hover:bg-destructive hover:text-destructive-foreground transition-colors duration-200"
                >
                  <span className="text-xs sm:text-sm">Remove</span>
                </Button>
              </div>
              <Button
                variant="outline"
                className="w-full mt-4 hover:bg-primary hover:text-primary-foreground transition-colors duration-200"
              >
                <CreditCard className="h-3 w-3 sm:h-4 sm:w-4 mr-1 sm:mr-2" />
                <span className="text-xs sm:text-sm">Update Payment Method</span>
              </Button>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};
