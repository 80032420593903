import React from "react";
import { DashboardSidebar } from "../sidebar/DashboardSidebar";
import { BillingPanel } from "./BillingPanel";
import { cn } from "@/components/utils";
import { useMediaQuery } from "@/hooks/use-media-query";
import { useDashboardStore } from "@/features/dashboard/dashboard-store";

export default function BillingPage() {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isExpanded = useDashboardStore((state) => state.isExpanded);

  return (
    <div className="flex min-h-screen">
      <DashboardSidebar />
      <main
        className={cn("flex-1 transition-all duration-300", isMobile ? "ml-0" : isExpanded ? "ml-[350px]" : "ml-16")}
      >
        <div className="h-full">
          <div className="sm:max-w-7xl sm:mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-semibold mb-6">Billing</h1>
            <div className="pt-8">
              <BillingPanel />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
