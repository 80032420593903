import React, { useState, useEffect, useMemo } from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Input } from "@/components/ui/input";
import { Search } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { DeviceCard } from "./DeviceCard";
import { CustomerSection } from "../sidebar/CustomerSections";

export const DevicesSection = ({ devices, customerDevices, selectedDevice, onDeviceSelect, isPartner }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300); // 300ms delay

    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  const filteredDevices = useMemo(() => {
    return devices.filter(
      (device) =>
        device.deviceId.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
        device.monitoring?.brand?.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
        device.monitoring?.refrigerant?.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
    );
  }, [devices, debouncedSearchTerm]);

  const filteredCustomerDevices = useMemo(() => {
    if (!customerDevices) return [];
    return customerDevices
      .map((customer) => ({
        ...customer,
        devices: customer.devices.filter(
          (device) =>
            device.deviceId.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
            device.monitoring?.brand?.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
            device.monitoring?.refrigerant?.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
            device.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
        ),
      }))
      .filter((customer) => customer.devices.length > 0);
  }, [customerDevices, debouncedSearchTerm]);

  const totalCustomerDevices = useMemo(
    () => filteredCustomerDevices.reduce((acc, customer) => acc + customer.devices.length, 0),
    [filteredCustomerDevices]
  );

  const DeviceList = ({ devices }) => (
    <ScrollArea>
      <div className="space-y-2">
        {devices.map((device) => (
          <DeviceCard
            key={device.deviceId}
            device={device}
            isCustomer={true}
            isSelected={selectedDevice?.deviceId === device.deviceId}
            onClick={() => onDeviceSelect(device)}
          />
        ))}
      </div>
    </ScrollArea>
  );

  const CustomerDeviceList = () => (
    <ScrollArea>
      <div className="pr-4">
        {filteredCustomerDevices.map(({ customer, devices }) => (
          <CustomerSection
            key={customer.id}
            customer={customer}
            devices={devices}
            selectedDevice={selectedDevice}
            onDeviceSelect={onDeviceSelect}
          />
        ))}
      </div>
    </ScrollArea>
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="space-y-4">
      <div className="relative">
        <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
        <Input
          placeholder="Search devices..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="pl-8 text-Black"
        />
      </div>

      {isPartner ? (
        <Tabs defaultValue="my-devices" className="w-full">
          <TabsList className="w-full">
            <TabsTrigger value="my-devices" className="flex-1">
              My Devices
              <Badge variant="secondary" className="ml-2">
                {filteredDevices.length}
              </Badge>
            </TabsTrigger>
            <TabsTrigger value="customer-devices" className="flex-1">
              Customer Devices
              <Badge variant="secondary" className="ml-2">
                {totalCustomerDevices}
              </Badge>
            </TabsTrigger>
          </TabsList>

          <TabsContent value="my-devices" className="mt-4">
            <DeviceList devices={filteredDevices} />
          </TabsContent>

          <TabsContent value="customer-devices" className="mt-4">
            <CustomerDeviceList />
          </TabsContent>
        </Tabs>
      ) : (
        <div className="mt-4">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center space-x-2">
              <h3 className="font-medium">My Devices</h3>
              <Badge variant="secondary">{filteredDevices.length}</Badge>
            </div>
          </div>
          <DeviceList devices={filteredDevices} />
        </div>
      )}
    </div>
  );
};
